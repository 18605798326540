import { InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';

export interface MetaSettings {
  applicationName: string;
  pageTitleSeparator: string;
  defaults: MetaSettingsDefaults;
}

export interface MetaSettingsDefaults {
  title: string;
  description?: string;
  keywords?: string[];
  image?: string;
  robots?: string;
}

export const META_SETTINGS = new InjectionToken<Observable<MetaSettings>>(
  ngDevMode ? 'MetaSettings' : ''
);
