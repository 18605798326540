import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import {
  RouterCancel,
  RouterDataResolved,
  RouterError,
  RouterNavigated,
  RouterNavigation,
  RouterRequest,
} from '@ngxs/router-plugin';
import { NgxsDevelopmentModule } from '@ngxs/store';

import { provideConfig } from '@cosmos/config';
import {
  withFlagsSelect,
  withFlagsToolbarInformation,
  withFlagsTranslations,
} from '@cosmos/dev-toolbar';
import {
  ExplicitFeatureFlagsService,
  provideFeatureFlagsService,
} from '@cosmos/feature-flags';
import { provideFeatureFlagsToolbarInitializer } from '@cosmos/feature-flags-toolbar';
import { CosmosUtilTranslationsModule } from '@cosmos/util-translations';

import type { EnvironmentConfig } from '../app/core/types/environment-config';

import { configOverrides, importOverrides } from './dev-overrides';
import { featureFlagsToolbarOptions } from './features';

// dev-asicentral.com
const ASICENTRAL_DOMAIN =
  configOverrides.asiCentralDomain ||
  process.env.ASICENTRAL_DOMAIN ||
  `dev-asicentral.com`;
const production = false;

export const environment: EnvironmentConfig = {
  production,

  serviceWorkerEnabled: false,

  imports: [
    NgxsDevelopmentModule.forRoot({
      warnOnUnhandledActions: {
        // These actions are dispatched by the router plugin, but we don't
        // handle them explicitly in any state. We're safe to ignore them
        // from being warned.
        ignore: [
          RouterRequest,
          RouterNavigation,
          RouterCancel,
          RouterError,
          RouterDataResolved,
          RouterNavigated,
        ],
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      filter(action, state) {
        return false;
      },
      disabled: false,
    }),
    CosmosUtilTranslationsModule.forRoot({
      production,
      appName: 'customer-portal',
    }),
    ...importOverrides,
  ],

  providers: [
    provideFeatureFlagsService(ExplicitFeatureFlagsService),
    provideConfig({
      asiStats: undefined,
      appInsights: undefined,
      smartlinkApiUrl: `https://api.${ASICENTRAL_DOMAIN}/v1`,
      espServiceApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/mars/api/esp`,

      venusApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/mars/api`,
      vulcanApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/mars/api`,
      marsApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/mars/api`,
      janusApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/mars/api`,
      espApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/mars/api/esp`,
      siriusApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/mars/api/esp`,
      neptunePublicApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/neptune-public/api`,
      sednaApiUrl: `https://asiservice.${ASICENTRAL_DOMAIN}/sedna/api`,
      pdfConverter: `https://pdfconverter.${ASICENTRAL_DOMAIN}/1.3/api/print`,

      myPromoOfficeDomain:
        configOverrides.myPromoOfficeDomain ||
        process.env.MYPROMOOFFICE_DOMAIN ||
        'dev-mypromooffice.com',

      asiCentralDomain: ASICENTRAL_DOMAIN,
    }),

    provideFeatureFlagsToolbarInitializer(
      featureFlagsToolbarOptions,
      withFlagsSelect(),
      withFlagsTranslations(),
      withFlagsToolbarInformation({
        url: `/asi/ngx-esp/commit/${process.env['GIT_SHA']}`,
      })
    ),
  ],
};
