@if (userIsDistributor) {
  <div
    class="project-host__header print:!hidden"
    *cosTranslate="let t; read: 'customerPortalCommon.feature-header'"
  >
    @if (previewingPresentation) {
      <span>{{ t('preview-presentation') }}</span>
    }
    @if (routesParam.orderId) {
      <span>{{ t('preview-order') }}</span>
    }
    @if (routesParam.quoteId) {
      <span>{{ t('preview-quote') }}</span>
    }
    @if (routesParam.invoiceId) {
      <span>{{ t('preview-invoice') }}</span>
    }
  </div>
}

@if (vm().showHeader) {
  <customer-portal-header class="print:!hidden" />
}
<div class="portal-body">
  <router-outlet />
</div>
<ng-container teleportOutlet="productCarousel" />
@if (vm().showFooter) {
  <customer-portal-footer />
}
