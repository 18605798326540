import { provideTippyLoader } from '@ngneat/helipopper/config';

let installed = false;
function installTippyCss() {
  if (installed) return;

  const path = '/assets/js/tippy/tippy-styles.min.js';
  import(/* @vite-ignore */ path).then(
    ({ tippyCss, tippyThemesLightCss, tippyAnimationsScaleCss }) => {
      const stylesList = [
        'tippy',
        tippyCss,
        'tippy-themes-light',
        tippyThemesLightCss,
        'tippy-animations-scale',
        tippyAnimationsScaleCss,
      ];

      // eslint-disable-next-line no-restricted-globals
      const doc = document;

      for (let index = 0; index < stylesList.length; index++) {
        const id = stylesList[index];
        const styles = stylesList[++index];

        if (doc.getElementById(id)) continue;

        const style = doc.createElement('style');
        style.id = id;
        style.appendChild(doc.createTextNode(styles));
        doc.head.appendChild(style);
      }
    }
  );

  installed = true;
}

export function provideAsyncTippyLoader() {
  return provideTippyLoader(() =>
    import('tippy.js').then((m) => {
      global_isBrowser && installTippyCss();
      return m;
    })
  );
}
