/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This code is almost a copy from https://github.com/angular/components/tree/16.2.10/src/material/bottom-sheet
 */

// eslint-disable-next-line no-restricted-syntax
import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import type { ComponentType } from '@angular/cdk/portal';
import {
  inject,
  Injectable,
  InjectionToken,
  Injector,
  type OnDestroy,
  type TemplateRef,
} from '@angular/core';

import {
  COS_BOTTOM_SHEET_DATA,
  CosBottomSheetConfig,
} from './bottom-sheet-config';
import { CosBottomSheetContainerComponent } from './bottom-sheet-container';
import { CosBottomSheetModule } from './bottom-sheet-module';
import { CosBottomSheetRef } from './bottom-sheet-ref';

/** Injection token that can be used to specify default bottom sheet options. */
export const COS_BOTTOM_SHEET_DEFAULT_OPTIONS =
  new InjectionToken<CosBottomSheetConfig>(
    ngDevMode ? 'cos-bottom-sheet-default-options' : ''
  );

/**
 * Service to trigger the bottom sheets panel.
 */
@Injectable({ providedIn: CosBottomSheetModule })
export class CosBottomSheet implements OnDestroy {
  private _overlay = inject(Overlay);
  private _bottomSheetRefAtThisLevel: CosBottomSheetRef<any> | null = null;
  private _parentBottomSheet = inject(CosBottomSheet, {
    optional: true,
    skipSelf: true,
  });
  private _defaultOptions = inject<CosBottomSheetConfig>(
    COS_BOTTOM_SHEET_DEFAULT_OPTIONS,
    { optional: true }
  );
  private _dialog: Dialog;

  /** Reference to the currently opened bottom sheet. */
  get _openedBottomSheetRef(): CosBottomSheetRef<any> | null {
    const parent = this._parentBottomSheet;
    return parent
      ? parent._openedBottomSheetRef
      : this._bottomSheetRefAtThisLevel;
  }

  set _openedBottomSheetRef(value: CosBottomSheetRef<any> | null) {
    if (this._parentBottomSheet) {
      this._parentBottomSheet._openedBottomSheetRef = value;
    } else {
      this._bottomSheetRefAtThisLevel = value;
    }
  }

  constructor() {
    const injector = inject(Injector);

    this._dialog = injector.get(Dialog);
  }

  /**
   * Opens a bottom sheet containing the given component.
   * @param component Type of the component to load into the bottom sheet.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened bottom sheet.
   */
  open<T, D = any, R = any>(
    component: ComponentType<T>,
    config?: CosBottomSheetConfig<D>
  ): CosBottomSheetRef<T, R>;

  /**
   * Opens a bottom sheet containing the given template.
   * @param template TemplateRef to instantiate as the bottom sheet content.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened bottom sheet.
   */
  open<T, D = any, R = any>(
    template: TemplateRef<T>,
    config?: CosBottomSheetConfig<D>
  ): CosBottomSheetRef<T, R>;

  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: CosBottomSheetConfig<D>
  ): CosBottomSheetRef<T, R> {
    const _config = {
      ...(this._defaultOptions || new CosBottomSheetConfig()),
      ...config,
    };
    let ref: CosBottomSheetRef<T, R>;

    // Preset required classes
    _config.backdropClass = _config.backdropClass || [];
    _config.backdropClass.push('cos-bottom-sheet-backdrop');
    _config.panelClass = _config.panelClass || [];
    _config.panelClass.push('cos-bottom-sheet-pane');

    this._dialog.open<R, D, T>(componentOrTemplateRef, {
      ..._config,
      // Disable closing since we need to sync it up to the animation ourselves.
      disableClose: true,
      // Disable closing on detachments so that we can sync up the animation.
      closeOnOverlayDetachments: false,
      maxWidth: '100%',
      container: CosBottomSheetContainerComponent,
      scrollStrategy:
        _config.scrollStrategy || this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .global()
        .centerHorizontally()
        .bottom('0'),
      templateContext: () => ({ bottomSheetRef: ref }),
      providers: (cdkRef, _cdkConfig, container) => {
        ref = new CosBottomSheetRef(
          cdkRef,
          _config,
          container as CosBottomSheetContainerComponent
        );
        return [
          { provide: CosBottomSheetRef, useValue: ref },
          { provide: COS_BOTTOM_SHEET_DATA, useValue: _config.data },
        ];
      },
    });

    // When the bottom sheet is dismissed, clear the reference to it.
    ref!.afterDismissed().subscribe(() => {
      // Clear the bottom sheet ref if it hasn't already been replaced by a newer one.
      if (this._openedBottomSheetRef === ref) {
        this._openedBottomSheetRef = null;
      }
    });

    if (this._openedBottomSheetRef) {
      // If a bottom sheet is already in view, dismiss it and enter the
      // new bottom sheet after exit animation is complete.
      this._openedBottomSheetRef
        .afterDismissed()
        .subscribe(() => ref.containerInstance?.enter());
      this._openedBottomSheetRef.dismiss();
    } else {
      // If no bottom sheet is in view, enter the new bottom sheet.
      ref!.containerInstance.enter();
    }

    this._openedBottomSheetRef = ref!;
    return ref!;
  }

  /**
   * Dismisses the currently-visible bottom sheet.
   * @param result Data to pass to the bottom sheet instance.
   */
  dismiss<R = any>(result?: R): void {
    if (this._openedBottomSheetRef) {
      this._openedBottomSheetRef.dismiss(result);
    }
  }

  ngOnDestroy() {
    if (this._bottomSheetRefAtThisLevel) {
      this._bottomSheetRefAtThisLevel.dismiss();
    }
  }
}
